import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddProduct = () => {
  const [sn, setSN] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [year, setYear] = useState("");
  const [condition, setCondition] = useState("");
  const [workbase, setWorkbase] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const saveProduct = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://is.synergylogistindo.com/products", {
        sn: sn,
        name: name,
        type: type,
        year: year,
        condition: condition,
        workbase: workbase
      });
      navigate("/products");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Products</h1>
      <h2 className="subtitle">Add New Product</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={saveProduct}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">No Lambung</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={sn}
                    onChange={(e) => setSN(e.target.value)}
                    placeholder="No Lambung"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Nama Alat</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nama Alat"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Tipe Alat</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    placeholder="Tipe Alat"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Tahun Alat</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    placeholder="Tahun Alat"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Kondisi</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={condition}
                      onChange={(e) => setCondition(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="Standby">Stand By</option>
                      <option value="Rusak - Dalam Perbaikan">Rusak - Dalam Perbaikan</option>
                      <option value="Bekerja">Bekerja</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Wilayah Kerja</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={workbase}
                      onChange={(e) => setWorkbase(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="Pomalaa">Pomalaa</option>
                      <option value="Kubu">Kubu</option>
                      <option value="Pangkalan Bun">Pangkalan Bun</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddProduct;
