import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoPerson, IoHome, IoLogOut, IoCogSharp, IoPeople, IoSettingsOutline, IoCartOutline, IoSettingsSharp } from "react-icons/io5";
import { FaTruckMonster, FaClipboardCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  };

  return (
    <div>
      <aside className="menu pl-2 has-shadow">
        <ul className="menu-list">
        <li>
            <NavLink to={"/dashboard"}>
              <IoHome style={{ marginRight: "10px" }} /> Dashboard
            </NavLink>
        </li>
        </ul>
        <p className="menu-label">Equipment</p>
        <ul className="menu-list">
          <li>
            <NavLink to={"/products"}>
              <FaTruckMonster style={{ marginRight: "10px" }} /> Products
            </NavLink>
          </li>
          <li>
            <NavLink to={"/preoperations"}>
              <IoSettingsOutline style={{ marginRight: "10px" }} /> Pre Operations
            </NavLink>
          </li>
          <li>
            <NavLink to={"/postoperations"}>
              <IoSettingsSharp style={{ marginRight: "10px" }} /> Post Operations
            </NavLink>
          </li>
        </ul>
        <p className="menu-label">Parts</p>
        <ul className="menu-list">
          <li>
            <NavLink to={"/spareparts"}>
              <IoCogSharp style={{ marginRight: "10px" }} /> Spare Parts
            </NavLink>
          </li>
          <li>
            <NavLink to={"/requests"}>
              <IoCartOutline style={{ marginRight: "10px" }} /> Requests
            </NavLink>
          </li>
          <li>
            <NavLink to={"/checkinouts"}>
              <FaClipboardCheck style={{ marginRight: "10px" }} /> Check In / Out
            </NavLink>
          </li>
        </ul>
        {user && user.role === "admin" && (
          <div>
            <p className="menu-label">Admin</p>
            <ul className="menu-list">
              <li>
                <NavLink to={"/users"}>
                  <IoPerson style={{ marginRight: "10px" }} /> Users
                </NavLink>
              </li>
              <li>
                <NavLink to={"/employees"}>
                  <IoPeople style={{ marginRight: "10px" }} /> Employees
                </NavLink>
              </li>
            </ul>
          </div>
        )}

        <p className="menu-label">Settings</p>
        <ul className="menu-list">
          <li>
            <button onClick={logout} className="button is-white">
              <IoLogOut style={{ marginRight: "10px" }} /> Logout
            </button>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
