import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormAddPostOperation = () => {
    const [products, setProducts] = useState([]);  
    const [productId, setProductId] = useState("");
    const [date, setDate] = useState("");
    const [posthm, setPostHM] = useState("");
    const [totalhm, setTotalHM] = useState("");
    const [harikerja, setHariKerja] = useState("");
    const [jumlahoperasi, setJumlahOperasi] = useState("");
    const [dailynote, setDailyNote] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    const handleProductSelect = (event) => {
      const selectedId = event.target.value
      setProductId(selectedId);
    };

    const fetchProducts = async () => {
      try {
          const response = await axios.get("https://is.synergylogistindo.com/products");
          setProducts(response.data);
      } catch (error) {
          console.error("Error fetching products:", error);
      }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const savePostOperation = async (e) => {
      e.preventDefault();
    try {
        await axios.post("https://is.synergylogistindo.com/postoperations", {
        productId: productId,
        date: date,
        posthm: posthm,
        totalhm: totalhm,
        harikerja: harikerja,
        jumlahoperasi: jumlahoperasi,
        dailynote: dailynote
        });

        navigate("/postoperations");
    } catch (error) {
        if (error.response) {
        setMsg(error.response.data.msg);
        }
    }
    };

  return (
    <div>
      <h1 className="title">Post Operations</h1>
      <h2 className="subtitle">Add New Post Operation</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={savePostOperation}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Alat</label>
                <div className="control">
                    <div className="select">
                        <select value={productId} onChange={handleProductSelect}>
                        <option value="">Select a product</option>
                        {products.map((product) => (
                            <option key={product.uuid} value={product.id}>
                            {product.sn}
                            </option>
                        ))}
                        </select>
                    </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Tanggal</label>
                <div className="control">
                  <input
                    type="date"
                    className="input"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    placeholder="Tanggal"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Post HM</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={posthm}
                    onChange={(e) => setPostHM(e.target.value)}
                    placeholder="Post HM"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Jumlah HM</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={totalhm}
                    onChange={(e) => setTotalHM(e.target.value)}
                    placeholder="Jumlah HM"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Hari Kerja</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={harikerja}
                    onChange={(e) => setHariKerja(e.target.value)}
                    placeholder="Hari Kerja"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Operasi Dilakukan</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={jumlahoperasi}
                    onChange={(e) => setJumlahOperasi(e.target.value)}
                    placeholder="Jumlah Operasi"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Keterangan</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={dailynote}
                    onChange={(e) => setDailyNote(e.target.value)}
                    placeholder="Keterangan"
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddPostOperation;
