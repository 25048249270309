import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const SparePartList = () => {
  const [spareparts, setSpareParts] = useState([]);

  useEffect(() => {
    getSpareParts();
  }, []);

  const getSpareParts = async () => {
    const response = await axios.get("https://is.synergylogistindo.com/spareparts");
    setSpareParts(response.data);
  };

  const deleteSparePart = async (sparepartId) => {
    await axios.delete(`https://is.synergylogistindo.com/spareparts/${sparepartId}`);
    getSpareParts();
  };

  const exportSparePart = async () => {
    try {
      const response = await axios.get('https://is.synergylogistindo.com/spareparts');
      const fetchedSpareParts = response.data;

      const csvData = fetchedSpareParts.map(sparepart => `${sparepart.name},${sparepart.part_number},${sparepart.brand},${sparepart.category},${sparepart.quantity}`);
      const csvString = 'Nama,No Part,Merk,Kategori,Jumlah\n' + csvData.join('\n');

      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'spareparts.csv';
      link.click();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="main-content">
      <h1 className="title">Spare Parts</h1>
      <h2 className="subtitle">List of Spare Parts</h2>
      <Link to="/spareparts/add" className="button is-primary mb-2 mr-2">
        Add New
      </Link>
      <button onClick={exportSparePart} className="button is-primary mb-2">
        Export
      </button>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nama Part</th>
            <th>No Part</th>
            <th>Merk</th>
            <th>Kategori</th>
            <th>Jumlah</th>
            <th>Created By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {spareparts.map((sparepart, index) => (
            <tr key={sparepart.uuid}>
              <td>{index + 1}</td>
              <td>{sparepart.name}</td>
              <td>{sparepart.part_number}</td>
              <td>{sparepart.brand}</td>
              <td>{sparepart.category}</td>
              <td>{sparepart.quantity}</td>
              <td>{sparepart.user.name}</td>
              <td>
                <Link
                  to={`/spareparts/edit/${sparepart.uuid}`}
                  className="button is-small is-info mr-2"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deleteSparePart(sparepart.uuid)}
                  className="button is-small is-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SparePartList;
