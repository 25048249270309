import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const RequestList = () => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    getRequests();
  }, []);

  const getRequests = async () => {
    const response = await axios.get("https://is.synergylogistindo.com/requests");
    setRequests(response.data);
  };

  const deleteRequest = async (requestId) => {
    await axios.delete(`https://is.synergylogistindo.com/requests/${requestId}`);
    getRequests();
  };

  const exportRequest = async () => {
    try {
      const response = await axios.get('https://is.synergylogistindo.com/requests');
      const fetchedRequests = response.data;

      const csvData = fetchedRequests.map(request => `${request.name},${request.number},${request.brand},${request.quantity},${request.remark}`);
      const csvString = 'Nama,No Part,Merk,Jumlah,Remark\n' + csvData.join('\n');

      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'requests.csv';
      link.click();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="main-content">
      <h1 className="title">Requests</h1>
      <h2 className="subtitle">List of Requests</h2>
      <Link to="/requests/add" className="button is-primary mb-2 mr-2">
        Add New
      </Link>
      <button onClick={exportRequest} className="button is-primary mb-2">
        Export
      </button>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nama Material</th>
            <th>No. Part</th>
            <th>Merk</th>
            <th>Jumlah</th>
            <th>Remark</th>
            <th>Created By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {requests.map((request, index) => (
            <tr key={request.uuid}>
              <td>{index + 1}</td>
              <td>{request.name}</td>
              <td>{request.number}</td>
              <td>{request.brand}</td>
              <td>{request.quantity}</td>
              <td>{request.remark}</td>
              <td>{request.user.name}</td>
              <td>
                <Link
                  to={`/requests/edit/${request.uuid}`}
                  className="button is-small is-info mr-2"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deleteRequest(request.uuid)}
                  className="button is-small is-danger mr-2"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RequestList;
