import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditRequest = () => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [brand, setBrand] = useState("");
  const [quantity, setQuantity] = useState("");
  const [remark, setRemark] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getRequestById = async () => {
      try {
        const response = await axios.get(
          `https://is.synergylogistindo.com/requests/${id}`
        );
        setName(response.data.name);
        setNumber(response.data.number);
        setBrand(response.data.brand);
        setQuantity(response.data.quantity);
        setRemark(response.data.remark);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getRequestById();
  }, [id]);

  const updateRequest = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://is.synergylogistindo.com/requests/{$id}`, {
        name: name,
        number: number,
        brand: brand,
        quantity: quantity,
        remark: remark,
      });
      navigate("/requests");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Requests</h1>
      <h2 className="subtitle">Edit Request</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateRequest}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Nama Material</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nama Material"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">No. Part</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="No. Part"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Merk</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                    placeholder="Merk"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Jumlah</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    placeholder="Jumlah"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Remark</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    placeholder="Remark"
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditRequest;
