import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const EmployeeList = () => {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    getEmployees();
  }, []);

  const getEmployees = async () => {
    const response = await axios.get("https://is.synergylogistindo.com/employees");
    setEmployees(response.data);
  };

  const deleteEmployee = async (employeeId) => {
    await axios.delete(`https://is.synergylogistindo.com/employees/${employeeId}`);
    getEmployees();
  };

  const employeeRequest = async () => {
    try {
      const response = await axios.get('https://is.synergylogistindo.com/employees');
      const fetchedEmployees = response.data;

      const csvData = fetchedEmployees.map(employee => `${employee.name},${employee.birth},${employee.position},${employee.workbase}`);
      const csvString = 'Nama,Tanggal Lahir,Jabatan,Wilayah Kerja\n' + csvData.join('\n');

      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'employees.csv';
      link.click();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="main-content">
      <h1 className="title">Employees</h1>
      <h2 className="subtitle">List of Employees</h2>
      <Link to="/employees/add" className="button is-primary mb-2 mr-2">
        Add New
      </Link>
      <button onClick={employeeRequest} className="button is-primary mb-2">
        Export
      </button>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nama</th>
            <th>Tanggal Lahir</th>
            <th>Jabatan</th>
            <th>Wilayah Kerja</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee, index) => (
            <tr key={employee.uuid}>
              <td>{index + 1}</td>
              <td>{employee.name}</td>
              <td>{employee.birth}</td>
              <td>{employee.position}</td>
              <td>{employee.workbase}</td>
              <td>
                <Link
                  to={`/employee/edit/${employee.uuid}`}
                  className="button is-small is-info mr-2"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deleteEmployee(employee.uuid)}
                  className="button is-small is-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeList;
