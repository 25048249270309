import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Users from "./pages/Users";
import Products from "./pages/Products";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import AddProduct from "./pages/AddProduct";
import EditProduct from "./pages/EditProduct";
import SpareParts from "./pages/SpareParts";
import AddSparePart from "./pages/AddSparePart";
import EditSparePart from "./pages/EditSparePart";
import Employees from "./pages/Employees";
import AddEmployee from "./pages/AddEmployee";
import EditEmployee from "./pages/EditEmployee";
import PreOperations from "./pages/PreOperations";
import AddPreOperation from "./pages/AddPreOperation";
import EditPreOperation from "./pages/EditPreOperation";
import Requests from "./pages/Requests";
import AddRequest from "./pages/AddRequest";
import EditRequest from "./pages/EditRequest";
import PostOperations from "./pages/PostOperations";
import AddPostOperation from "./pages/AddPostOperation";
import EditPostOperation from "./pages/EditPostOperation";
import CheckInOuts from "./pages/CheckInOuts";
import AddCheckInOut from "./pages/AddCheckInOut";
import EditCheckInOut from "./pages/EditCheckInOut";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users/edit/:id" element={<EditUser />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/add" element={<AddProduct />} />
          <Route path="/products/edit/:id" element={<EditProduct />} />
          <Route path="/spareparts" element={<SpareParts />} />
          <Route path="/spareparts/add" element={<AddSparePart />} />
          <Route path="/spareparts/edit/:id" element={<EditSparePart />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/employees/add" element={<AddEmployee />} />
          <Route path="/employees/edit/:id" element={<EditEmployee />} />
          <Route path="/preoperations" element={<PreOperations />} />
          <Route path="/preoperations/add" element={<AddPreOperation />} />
          <Route path="/preoperations/edit/:id" element={<EditPreOperation />} />
          <Route path="/requests" element={<Requests />} />
          <Route path="/requests/add" element={<AddRequest />} />
          <Route path="/requests/edit/:id" element={<EditRequest />} />
          <Route path="/postoperations" element={<PostOperations />} />
          <Route path="/postoperations/add" element={<AddPostOperation />} />
          <Route path="/postoperations/edit/:id" element={<EditPostOperation />} />
          <Route path="/checkinouts" element={<CheckInOuts />} />
          <Route path="/checkinouts/add" element={<AddCheckInOut />} />
          <Route path="/checkinouts/edit/:id" element={<EditCheckInOut />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
