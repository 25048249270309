import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import axios from 'axios';

const Welcome = () => {
  const { user } = useSelector((state) => state.auth);
  const [conditionData, setConditionData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [requestCount, setRequestCount] = useState(0); // State for request count
  const [workbaseChartData, setWorkbaseChartData] = useState([]);
  const [positionChartData, setPositionChartData] = useState([]);

  useEffect(() => {
    // Fetch data from your API and update the state
    axios.get('https://is.synergylogistindo.com/products') // Replace with your actual API endpoint
      .then(productResponse => {
        // Assuming the response contains an array of product objects
        const products = productResponse.data;
        const conditionCounts = {
          Standby: 0,
          Bekerja: 0,
          'Rusak - Dalam Perbaikan': 0,
        };
        const typeCounts = {
          'Dump Truck': 0,
          Excavator: 0,
          Buldozer: 0,
        };
        products.forEach(product => {
          const { condition, type } = product;
          if (conditionCounts.hasOwnProperty(condition)) {
            conditionCounts[condition]++;
          }
          if (typeCounts.hasOwnProperty(type)) {
            typeCounts[type]++;
          }
        });

        const conditionChartData = Object.keys(conditionCounts).map(condition => ({
          condition,
          count: conditionCounts[condition],
        }));

        const typeChartData = Object.keys(typeCounts).map(type => ({
          type,
          count: typeCounts[type],
        }));

        setConditionData(conditionChartData);
        setTypeData(typeChartData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

    // Fetch all data from the "requests" table and count the records
    axios.get('https://is.synergylogistindo.com/requests') // Replace with your actual API endpoint for requests
      .then(requestResponse => {
        // Assuming the response contains an array of request objects
        const requests = requestResponse.data;
        const count = requests.length;
        setRequestCount(count);
      })
      .catch(error => {
        console.error('Error fetching request data:', error);
      });

    // Fetch data from the "employee" table and create charts for workbase and position
    axios.get('https://is.synergylogistindo.com/employees') // Replace with your actual API endpoint for employees
      .then(employeeResponse => {
        // Assuming the response contains an array of employee objects
        const employees = employeeResponse.data;
        
        // Filter employees by workbase and position values
        const workbaseData = employees.filter(employee =>
          ["Kubu", "Pangkalan Bun", "Pomalaa"].includes(employee.workbase)
        );

        const positionData = employees.filter(employee =>
          ["Admin", "Mekanik", "Helper", "Fuelman", "Operator"].includes(employee.position)
        );

        // Count the filtered data for workbase and position
        const workbaseCounts = {};
        const positionCounts = {};

        workbaseData.forEach(employee => {
          const { workbase } = employee;
          workbaseCounts[workbase] = (workbaseCounts[workbase] || 0) + 1;
        });

        positionData.forEach(employee => {
          const { position } = employee;
          positionCounts[position] = (positionCounts[position] || 0) + 1;
        });

        // Format data for workbase chart
        const workbaseChartData = Object.keys(workbaseCounts).map(workbase => ({
          workbase,
          count: workbaseCounts[workbase],
        }));

        // Format data for position chart
        const positionChartData = Object.keys(positionCounts).map(position => ({
          position,
          count: positionCounts[position],
        }));

        setWorkbaseChartData(workbaseChartData);
        setPositionChartData(positionChartData);
      })
      .catch(error => {
        console.error('Error fetching employee data:', error);
      });
  }, []);

  return (
    <div className="main-content">
      <h1 className="title">Dashboard</h1>
      <h2 className="subtitle">
        Welcome Back <strong>{user && user.name}</strong>
      </h2>
      <div>
        <h2 className="chart-heading">Condition Chart</h2>
        <BarChart width={500} height={300} data={conditionData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="condition" />
          <YAxis type="number" allowDecimals={false} tickCount={5} />
          <Tooltip />
          <Legend 
          iconSize={10} // Customize the size of legend icons
          iconType="circle" // Change the legend icon shape (default is "square")
          wrapperStyle={{ paddingLeft: "20px" }} // Adjust the legend's position
          formatter={(entry) => (
            <span style={{ color: entry.color }}>
              Jumlah
            </span>
          )} // Customize the legend label format
          />
          <Bar dataKey="count" fill="red" />
        </BarChart>
      </div>
      <div>
        <h2 className="chart-heading">Type Chart</h2>
        <BarChart width={500} height={300} data={typeData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="type" />
          <YAxis type="number" allowDecimals={false} tickCount={5} />
          <Tooltip />
          <Legend 
          iconSize={10} // Customize the size of legend icons
          iconType="circle" // Change the legend icon shape (default is "square")
          wrapperStyle={{ paddingLeft: "20px" }} // Adjust the legend's position
          formatter={(entry) => (
            <span style={{ color: entry.color }}>
              Jumlah
            </span>
          )}
          />
          <Bar dataKey="count" fill="orange" />
        </BarChart>
      </div>
      <div>
        <h2 className="chart-heading">Workbase Chart</h2>
        <BarChart width={500} height={300} data={workbaseChartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="workbase" />
          <YAxis type="number" allowDecimals={false} tickCount={5} />
          <Tooltip />
          <Legend 
          iconSize={10} // Customize the size of legend icons
          iconType="circle" // Change the legend icon shape (default is "square")
          wrapperStyle={{ paddingLeft: "20px" }} // Adjust the legend's position
          formatter={(entry) => (
            <span style={{ color: entry.color }}>
              Jumlah
            </span>
          )}
          />
          <Bar dataKey="count" fill="blue" />
        </BarChart>
      </div>
      <div>
        <h2 className="chart-heading">Position Chart</h2>
        <BarChart width={500} height={300} data={positionChartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="position" />
          <YAxis type="number" allowDecimals={false} tickCount={5} />
          <Tooltip />
          <Legend 
          iconSize={10} // Customize the size of legend icons
          iconType="circle" // Change the legend icon shape (default is "square")
          wrapperStyle={{ paddingLeft: "20px" }} // Adjust the legend's position
          formatter={(entry) => (
            <span style={{ color: entry.color }}>
              Jumlah
            </span>
          )}
          />
          <Bar dataKey="count" fill="green" />
        </BarChart>
      </div>
      <div className="mini-table">
        <h2 className="chart-heading">Request</h2>
        <table className="request-table">
          <tbody>
            <tr>
              <td>Jumlah Request Barang/Part Saat Ini</td>
              <td>{requestCount}</td>
            </tr>
            <tr>
              <td colSpan="2" className="table-divider" style={{ border: '2px solid black' }}></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Welcome;
