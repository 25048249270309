import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditCheckInOut = () => {
  const [check, setCheck] = useState("");
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [part_number, setPartNumber] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [quantity, setQuantity] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getCheckInOuttById = async () => {
      try {
        const response = await axios.get(
          `https://is.synergylogistindo.com/checkinouts/${id}`
        );
        setCheck(response.data.check);
        setDate(response.data.date);
        setName(response.data.name);
        setPartNumber(response.data.part_number);
        setBrand(response.data.brand);
        setCategory(response.data.category);
        setQuantity(response.data.quantity);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getCheckInOuttById();
  }, [id]);

  const updateCheckInOut = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://is.synergylogistindo.com/checkinouts/${id}`, {
            check: check,
            date: date,
            name: name,
            part_number: part_number,
            brand: brand,
            category: category,
            quantity: quantity,
      });
      navigate("/checkinouts");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Check In / Out</h1>
      <h2 className="subtitle">Edit Check In / Out</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateCheckInOut}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Check</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={check}
                      onChange={(e) => setCheck(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="In">In</option>
                      <option value="Out">Out</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Tanggal</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    placeholder="Tanggal"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Nama</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nama"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">No Part</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={part_number}
                    onChange={(e) => setPartNumber(e.target.value)}
                    placeholder="No Part"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Merk</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                    placeholder="Merk"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Kategori</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    placeholder="Kategori"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Jumlah</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    placeholder="Jumlah"
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditCheckInOut;
