import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import axios from "axios";

const PreOperationList = () => {
  const [preOperations, setPreOperations] = useState([]);

  useEffect(() => {
    getPreOperations();
  }, []);

  const getPreOperations = async () => {
    const response = await axios.get("https://is.synergylogistindo.com/preoperations");
    setPreOperations(response.data);
  };

  const deletePreOperation = async (preOperationId) => {
    await axios.delete(`https://is.synergylogistindo.com/preoperations/${preOperationId}`);
    getPreOperations();
  };

  const exportPreOperation = async () => {
    try {
      const response = await axios.get('https://is.synergylogistindo.com/preoperations');
      const fetchedPreOperations = response.data;

      const csvData = fetchedPreOperations.map(preoperation =>
        `${preoperation.product.name},${preoperation.product.sn},${preoperation.date},${preoperation.operator},${preoperation.checker},${preoperation.prehm},${preoperation.gemuk},${preoperation.oli_hidrolik},${preoperation.oli_motorswing},${preoperation.oli_finaldrive},${preoperation.oli_mesin},${preoperation.oli_motor},${preoperation.oli_gardan},${preoperation.oli_transmisi},${preoperation.radiant_coolant},${preoperation.solar},${preoperation.air},${preoperation.prekm},${preoperation.tekanan_angin},${preoperation.minyakrem},${preoperation.dailynote}`
        );
      const csvString = 'Nama Alat,No Lambung,Tanggal,Operator,Checker,Pre HM,Gemuk,Oli Hidrolik,Oli Motor Swing,Oli Final Drive,Oli Mesin,Oli Motor,Oli Gardan,Oli Transmisi,Radiant Coolant,Solar,Air,Pre KM,Tekanan Angin,Minyak Rem,Keterangan\n' + csvData.join('\n');

      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'preoperation.csv';
      link.click();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="main-content">
      <h1 className="title">Pre Operations</h1>
      <h2 className="subtitle">List of Pre Operations</h2>
      <Link to="/preoperations/add" className="button is-primary mb-2 mr-2">
        Add New
      </Link>
      <button onClick={exportPreOperation} className="button is-primary mb-2">
        Export
      </button>
      <div className="outer">
      <div className="inner">
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Alat</th>
            <th>No Lambung</th>
            <th>Tanggal</th>
            <th>Operator</th>
            <th>Checker</th>
            <th>Pre HM</th>
            <th>Gemuk</th>
            <th>Oli Hidrolik</th>
            <th>Oli Motor Swing</th>
            <th>Oli Final Drive</th>
            <th>Oli Mesin</th>
            <th>Oli Motor</th>
            <th>Oli Gardan</th>
            <th>Oli Transmisi</th>
            <th>Radiant Coolant</th>
            <th>Solar</th>
            <th>Air</th>
            <th>Pre KM</th>
            <th>Tekanan Angin</th>
            <th>Minyak Rem</th>
            <th>Keterangan</th>
            <th>Created By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {preOperations.map((preOperation, index) => (
            <tr key={preOperation.uuid}>
              <td>{index + 1}</td>
              <td>{preOperation.product.name}</td>
              <td>{preOperation.product.sn}</td>
              <td>{preOperation.date}</td>
              <td>{preOperation.operator}</td>
              <td>{preOperation.chekcer}</td>
              <td>{preOperation.prehm}</td>
              <td>{preOperation.gemuk}</td>
              <td>{preOperation.oli_hidrolik}</td>
              <td>{preOperation.oli_motorswing}</td>
              <td>{preOperation.oli_finaldrive}</td>
              <td>{preOperation.oli_mesin}</td>
              <td>{preOperation.oli_motor}</td>
              <td>{preOperation.oli_gardan}</td>
              <td>{preOperation.oli_transmisi}</td>
              <td>{preOperation.radiant_coolant}</td>
              <td>{preOperation.solar}</td>
              <td>{preOperation.air}</td>
              <td>{preOperation.prekm}</td>
              <td>{preOperation.tekanan_angin}</td>
              <td>{preOperation.minyakrem}</td>
              <td className="wrap-cell">{preOperation.dailynote}</td>
              <td>{preOperation.user.name}</td>
              <td>
                <Link
                  to={`/preoperations/edit/${preOperation.uuid}`}
                  className="button is-small is-info mr-2"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deletePreOperation(preOperation.uuid)}
                  className="button is-small is-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div>
    </div>
  );
};

export default PreOperationList;
