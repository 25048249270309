import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditEmployee = () => {
  const [name, setName] = useState("");
  const [birth, setBirth] = useState("");
  const [position, setPosition] = useState("");
  const [workbase, setWorkbase] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getEmployeeById = async () => {
      try {
        const response = await axios.get(`https://is.synergylogistindo.com/employees/${id}`);
        setName(response.data.name);
        setBirth(response.data.birth);
        setPosition(response.data.position);
        setWorkbase(response.data.workbase);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getEmployeeById();
  }, [id]);

  const updateEmployee = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://is.synergylogistindo.com/employees", {
        name: name,
        birth: birth,
        position: position,
        workbase: workbase,
      });
      navigate("/employees");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  return (
    <div>
      <h1 className="title">Employees</h1>
      <h2 className="subtitle">Update Employee</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateEmployee}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Nama</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Tanggal Lahir</label>
                <div className="control">
                  <input
                    type="date"
                    className="input"
                    value={birth}
                    onChange={(e) => setBirth(e.target.value)}
                    placeholder="Tanggal Lahir"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Jabatan</label>
                <div className="control">
                <div className="select is-fulwidth">
                  <select
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="Admin">Admin</option>
                    <option value="Mekanik">Mekanik</option>
                    <option value="Helper">Helper</option>
                    <option value="Fuelman">Fuelman</option>
                    <option value="Operator">Operator</option>
                    </select>
                </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Wilayah Kerja</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={workbase}
                      onChange={(e) => setWorkbase(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="Pomalaa">Pomalaa</option>
                      <option value="Kubu">Kubu</option>
                      <option value="Pangkalan Bun">Pangkalan Bun</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditEmployee;
