import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditSparePart = () => {
    const [name, setName] = useState("");
    const [part_number, setPartNumber] = useState("");
    const [brand, setBrand] = useState("");
    const [category, setCategory] = useState("");
    const [quantity, setQuantity] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const { id } = useParams();

  useEffect(() => {
    const getSparePartById = async () => {
      try {
        const response = await axios.get(
          `https://is.synergylogistindo.com/spareparts/${id}`
        );
        setName(response.data.name);
        setPartNumber(response.data.part_number);
        setBrand(response.data.brand);
        setCategory(response.data.category);
        setQuantity(response.data.quantity);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getSparePartById();
  }, [id]);

  const updateSparePart = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://is.synergylogistindo.com/spareparts/${id}`, {
        name: name,
        part_number: part_number,
        brand: brand,
        category: category,
        quantity: quantity
      });
      navigate("/spareparts");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Spare Parts</h1>
      <h2 className="subtitle">Edit Spare Part</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateSparePart}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Nama Part</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nama Part"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">No Part</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={part_number}
                    onChange={(e) => setPartNumber(e.target.value)}
                    placeholder="No Part"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Merk</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                    placeholder="Brand"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Kategori</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    placeholder="Kategori"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Jumlah</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    placeholder="Jumlah"
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditSparePart;
