import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const FormEditPreOperation = () => {
    const [products, setProducts] = useState([]);  
    const [productId, setProductId] = useState("");
    const [date, setDate] = useState("");
    const [operator, setOperator] = useState("");
    const [checker, setChecker] = useState("");
    const [prehm, setPreHM] = useState("");
    const [gemuk, setGemuk] = useState("");
    const [oli_hidrolik, setOliHidrolik] = useState("");
    const [oli_motorswing, setOliMotorSwing] = useState("");
    const [oli_finaldrive, setOliFinalDrive] = useState("");
    const [oli_mesin, setOliMesin] = useState("");
    const [oli_motor, setOliMotor] = useState("");
    const [oli_gardan, setOliGardan] = useState("");
    const [oli_transmisi, setOliTransmisi] = useState("");
    const [radiant_coolant, setRadiantCoolant] = useState("");
    const [solar, setSolar] = useState("");
    const [air, setAir] = useState("");
    const [prekm, setPreKM] = useState("");
    const [tekanan_angin, setTekananAngin] = useState("");
    const [minyakrem, setMinyakRem] = useState("");
    const [dailynote, setDailyNote] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        fetchProducts();
        const getPreOperationById = async () => {
            try {
                const response = await axios.get(
                  `https://is.synergylogistindo.com/preoperations/${id}`
                );
                setProductId(response.data.productId);
                setDate(response.data.date);
                setOperator(response.data.operator);
                setChecker(response.data.checker);
                setPreHM(response.data.prehm);
                setGemuk(response.data.gemuk);
                setOliHidrolik(response.data.oli_hidrolik);
                setOliMotorSwing(response.data.oli_motorswing);
                setOliFinalDrive(response.data.oli_finaldrive);
                setOliMesin(response.data.oli_mesin);
                setOliMotor(response.data.oli_motor);
                setOliGardan(response.data.oli_gardan);
                setOliTransmisi(response.data.oli_transmisi);
                setRadiantCoolant(response.data.radiant_coolant);
                setSolar(response.data.solar);
                setAir(response.data.air);
                setPreKM(response.data.prekm);
                setTekananAngin(response.data.tekanan_angin);
                setMinyakRem(response.data.minyakrem);
                setDailyNote(response.data.dailynote);
            } catch (error) {
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            }
        };
        getPreOperationById();
    }, [id]);
    
    const fetchProducts = async () => {
        try {
            const response = await axios.get("https://is.synergylogistindo.com/products");
            setProducts(response.data);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    const updatePreOperation = async (e) => {
    e.preventDefault();
    try {
        await axios.patch(`https://is.synergylogistindo.com/preoperations/${id}`, {
        productId: productId,
        date: date,
        operator: operator,
        checker: checker,
        prehm: prehm,
        gemuk: gemuk,
        oli_hidrolik: oli_hidrolik,
        oli_motorswing: oli_motorswing,
        oli_finaldrive: oli_finaldrive,
        oli_mesin: oli_mesin,
        oli_motor: oli_motor,
        oli_gardan: oli_gardan,
        oli_transmisi: oli_transmisi,
        radiant_coolant: radiant_coolant,
        solar: solar,
        air: air,
        prekm: prekm,
        tekanan_angin: tekanan_angin,
        minyakrem: minyakrem,
        dailynote: dailynote
        });
        navigate("/preoperations");
    } catch (error) {
        if (error.response) {
        setMsg(error.response.data.msg);
        }
    }
    };

    const handleProductSelect = (event) => {
    setProductId(event.target.value);
  };

  return (
    <div>
      <h1 className="title">Pre Operations</h1>
      <h2 className="subtitle">Edit Pre Operation</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updatePreOperation}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Alat</label>
                <div className="control">
                    <div className="select">
                        <select value={productId} onChange={handleProductSelect}>
                        <option value="">Select a product</option>
                        {products.map((product) => (
                            <option key={product.uuid} value={product.id}>
                            {product.sn}
                            </option>
                        ))}
                        </select>
                    </div>
                </div>
              </div>
              <div className="field">
                <label className="label">Tanggal</label>
                <div className="control">
                  <input
                    type="date"
                    className="input"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    placeholder="Tanggal"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Operator</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={operator}
                    onChange={(e) => setOperator(e.target.value)}
                    placeholder="Operator"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Checker</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={checker}
                    onChange={(e) => setChecker(e.target.value)}
                    placeholder="Checker"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Pre HM</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={prehm}
                    onChange={(e) => setPreHM(e.target.value)}
                    placeholder="Pre HM"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Gemuk</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={gemuk}
                    onChange={(e) => setGemuk(e.target.value)}
                    placeholder="Gemuk"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Oli Hidrolik</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={oli_hidrolik}
                    onChange={(e) => setOliHidrolik(e.target.value)}
                    placeholder="Oli Hidrolik"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Oli Motor Swing</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={oli_motorswing}
                    onChange={(e) => setOliMotorSwing(e.target.value)}
                    placeholder="Oli Motor Swing"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Oli Final Drive</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={oli_finaldrive}
                    onChange={(e) => setOliFinalDrive(e.target.value)}
                    placeholder="Oli Final Drive"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Oli Mesin</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={oli_mesin}
                    onChange={(e) => setOliMesin(e.target.value)}
                    placeholder="Oli Mesin"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Oli Motor</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={oli_motor}
                    onChange={(e) => setOliMotor(e.target.value)}
                    placeholder="Oli Motor"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Oli Gardan</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={oli_gardan}
                    onChange={(e) => setOliGardan(e.target.value)}
                    placeholder="Oli Gardan"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Oli Transmisi</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={oli_transmisi}
                    onChange={(e) => setOliTransmisi(e.target.value)}
                    placeholder="Oli Transmisi"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Radiant Coolant</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={radiant_coolant}
                    onChange={(e) => setRadiantCoolant(e.target.value)}
                    placeholder="Radiant Coolant"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Solar</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={solar}
                    onChange={(e) => setSolar(e.target.value)}
                    placeholder="Solar"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Air</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={air}
                    onChange={(e) => setAir(e.target.value)}
                    placeholder="Air"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Pre KM</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={prekm}
                    onChange={(e) => setPreKM(e.target.value)}
                    placeholder="Pre KM"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Tekanan Angin</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={tekanan_angin}
                    onChange={(e) => setTekananAngin(e.target.value)}
                    placeholder="Tekanan Angin"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Minyak Rem</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={minyakrem}
                    onChange={(e) => setMinyakRem(e.target.value)}
                    placeholder="Minyak Rem"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Keterangan</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={dailynote}
                    onChange={(e) => setDailyNote(e.target.value)}
                    placeholder="Keterangan"
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditPreOperation;
