import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const PostOperationList = () => {
  const [postOperations, setPostOperations] = useState([]);

  useEffect(() => {
    getPostOperations();
  }, []);

  const getPostOperations = async () => {
    const response = await axios.get("https://is.synergylogistindo.com/postoperations");
    setPostOperations(response.data);
  };

  const deletePostOperations = async (postOperationId) => {
    await axios.delete(`https://is.synergylogistindo.com/postoperations/${postOperationId}`);
    getPostOperations();
  };

  const exportPostOperation = async () => {
    try {
      const response = await axios.get('https://is.synergylogistindo.com/postoperations');
      const fetchedPostOperations = response.data;

      const csvData = fetchedPostOperations.map(postoperation =>
        `${postoperation.product.name},${postoperation.product.sn},${postoperation.date},${postoperation.posthm},${postoperation.totalhm},${postoperation.harikerja},${postoperation.jumlahoperasi},${postoperation.dailynote}`
        );
      const csvString = 'Nama Alat,No Lambung,Tanggal,Post HM,Total HM,Hari Kerja,Jumlah Operasi Dilakukan,Keterangan\n' + csvData.join('\n');

      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'postoperations.csv';
      link.click();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="main-content">
      <h1 className="title">Post Operations</h1>
      <h2 className="subtitle">List of Post Operations</h2>
      <Link to="/postoperations/add" className="button is-primary mb-2 mr-2">
        Add New
      </Link>
      <button onClick={exportPostOperation} className="button is-primary mb-2">
        Export
      </button>
      <div className="outer">
      <div className="inner">
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Alat</th>
            <th>No Lambung</th>
            <th>Tanggal</th>
            <th>Post HM</th>
            <th>Jumlah HM</th>
            <th>Hari Kerja</th>
            <th>Operasi Dilakukan</th>
            <th>Keterangan</th>
            <th>Created By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {postOperations.map((postOperation, index) => (
            <tr key={postOperation.uuid}>
              <td>{index + 1}</td>
              <td>{postOperation.product.name}</td>
              <td>{postOperation.product.sn}</td>
              <td>{postOperation.date}</td>
              <td>{postOperation.posthm}</td>
              <td>{postOperation.totalhm}</td>
              <td>{postOperation.harikerja}</td>
              <td>{postOperation.jumlahoperasi}</td>
              <td className="wrap-cell">{postOperation.dailynote}</td>
              <td>{postOperation.user.name}</td>
              <td>
                <Link
                  to={`/postoperations/edit/${postOperation.uuid}`}
                  className="button is-small is-info mr-2"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deletePostOperations(postOperation.uuid)}
                  className="button is-small is-danger mr-2"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div>
    </div>
  );
};

export default PostOperationList;
