import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const ProductList = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const response = await axios.get("https://is.synergylogistindo.com/products");
    setProducts(response.data);
  };

  const deleteProduct = async (productId) => {
    await axios.delete(`https://is.synergylogistindo.com/products/${productId}`);
    getProducts();
  };

  const exportProduct = async () => {
    try {
      const response = await axios.get('https://is.synergylogistindo.com/products');
      const fetchedProducts = response.data;

      const csvData = fetchedProducts.map(product => `${product.sn},${product.name},${product.type},${product.year},${product.condition},${product.workbase}`);
      const csvString = 'No Lambung,Nama Alat,Tipe,Tahun,Kondisi,Wilayah Kerja\n' + csvData.join('\n');

      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'products.csv';
      link.click();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="main-content">
      <h1 className="title">Products</h1>
      <h2 className="subtitle">List of Products</h2>
      <Link to="/products/add" className="button is-primary mb-2 mr-2">
        Add New
      </Link>
      <button onClick={exportProduct} className="button is-primary mb-2">
        Export
      </button>
      <div className="outer">
      <div className="inner">
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>No Lambung</th>
            <th>Nama Alat</th>
            <th>Tipe Alat</th>
            <th>Tahun Alat</th>
            <th>Kondisi Alat</th>
            <th>Wilayah Kerja</th>
            <th>Created By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={product.uuid}>
              <td>{index + 1}</td>
              <td>{product.sn}</td>
              <td>{product.name}</td>
              <td>{product.type}</td>
              <td>{product.year}</td>
              <td>{product.condition}</td>
              <td>{product.workbase}</td>
              <td>{product.user.name}</td>
              <td>
                <Link
                  to={`/products/edit/${product.uuid}`}
                  className="button is-small is-info mr-2"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deleteProduct(product.uuid)}
                  className="button is-small is-danger mr-2"
                >
                  Delete
                </button>
                <Link
                  to={"/preoperations/add"}
                  className="button is-small is-info mr-2"
                >
                  PreOp
                </Link>
                <Link
                  to={"/postoperations/add"}
                  className="button is-small is-info mr-2"
                >
                  PostOp
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div>
    </div>
  );
};

export default ProductList;
